import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import sobreStyles from './sobre.module.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const SobrePage = () => {
  const data = useStaticQuery(graphql`
    query {
      sobreImagem: file(relativePath: { eq: "logo-imagem-sobre-cerruns.png" }) {
        childImageSharp {
          fluid (quality: 80, maxWidth: 1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Sobre"/>
      <div className={sobreStyles.mainContent}>
        <div className={sobreStyles.sobreTexto}>
          <h1>Sobre</h1>
          <p>Conheça mais sobre nós.</p>
        </div>
      </div>
      <Img fluid={data.sobreImagem.childImageSharp.fluid} />
      <div className={sobreStyles.mainContent}>
        <h2 className={sobreStyles.tituloTopico}>A empresa</h2>  
        <p>
          A Cerruns está no mercado desde 1984. Nosso primeiro alarme por presença foi lançado em 1989, há 30 anos atrás. Ao longo desses anos, fomos adquirindo conhecimento e desenvolvendo os produtos para atender nossos clientes.
        </p>
        <h2 className={sobreStyles.tituloTopico}>Nossa missão</h2>
        <p>  
          Desenvolver e fabricar produtos de qualidade que deixam os clientes satisfeitos, além de presar pelo bom atendimento na venda e no pós-venda.
        </p>
      </div>
    </Layout>
  )
}

export default SobrePage